.container {
  border-radius: 8px !important;
  // height: 48px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 18px;
  }
}
