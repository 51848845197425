// default colors
$black: #000000;
$white: #ffffff;

// primary
$brown: #c0a270;
$brown-lighter: #c5aa71;
$brown-grey: #F6F2EA;

$green: #15ab72;
$green-lighter: #00be3873;

$grey: #ababab;
$grey-lighter: #f2f2f2;
$grey-lighter2: #f8f8f8;
$grey-darker: #4b4b4d;
$grey-darker2: #777777;

$yellow: #d3a332;
$yellow-lighter: rgb(255 179 0 / 40%);

$red: #ad0303;
$red-lighter: rgb(255 19 19 / 40%);

$blue: #3A71FF;

$vivid: #C0C0C0;
$vivid-lighter: #F6F6F6;

// secondary
