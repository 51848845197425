.gridContainer {
  background-color: #f2f2f2;
  border-radius: 8px;
  margin-top: 1px !important;
  padding-bottom: 20px;

  .item {
    p {
      padding-bottom: 10px;
    }
  }
}

.title {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 30px;
  font-weight: bold;
}

.error_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
}

.viewIcon {
  width: 20px;
  height: 20px;
}
