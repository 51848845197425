@import 'variables.scss';

.globalGrid {
  padding: 10px 30px;
}

.problemItem {
  border-radius: 5px;
  background-color: $brown-grey;
  color: $grey-darker2;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 13px;
  height: 50px;

  button {
    text-transform: capitalize;
    color: $red;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
}

.addOtherProblemButton {
  text-transform: capitalize;
  margin-top: 10px;
}

.container {
  border: solid 3px $brown-lighter;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 20px;
  
  .containerTitle {    
    display: flex;
    position: relative;
    height: 40px;
    bottom: 32px;
    align-items: center;
    margin-bottom: -20px;
    background-color: $white;
    width: fit-content;

    h4 {
      padding: 0 8px;
      margin: 0;
      font-size: 18px;
    }

    .scheduleDateSeparator {
      height: 3px;
      background-color: $brown-lighter;
      width: 100%;
    }

    .scheduleDate {
      text-wrap: nowrap;
      padding: 0 8px;
      font-size: 13px;
    }
  }
}

.bgGrid {
  display: grid;
  grid-template-columns: auto 40%;
  gap: 5px;
}

.bgVivid, .bgGrey, .bgVividBordered {
  padding: 4px;
  border-radius: 5px;
  background-color: $vivid-lighter;
  color: $grey-darker2;
  margin-bottom: 5px;

  a {
    color: $brown;
    font-weight: 500;
  }

  b {
    color: $black;
  }
}

.bgVividBordered {
  border: solid 1px $vivid;
}

.bgGrey {
  background-color: $brown-grey;
}

.modalRemoveProblem {
  width: 450px;
  max-width: 450px;

  button {
    text-transform: capitalize;
  }

  .containerModalRemoveProblem {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    
    h2 {
      color: $grey-darker2;
      font-size: 18px;
    }

    .inputRemoveProblem {
      width: 270px;
      margin: 16px auto 25px auto;
    }
  }

  .buttonsModalRemoveProblem {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

.prontuario {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  .prontuarioTitle {
    background-color: $brown-lighter;
    color: $white;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 2px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: max-content;
    position: relative;
    left: -20px;

    svg {
      margin-left: 5px;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .prontuarioSubtitle {
    color: $grey-darker2;
    font-size: 13px;

    h5 {
      padding: 0;
      margin: 12px 0;
      font-size: 13px;
      font-weight: 700;

      span {
        background-color: $brown-lighter;
        padding: 4px 6px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 800;
        color: $white;
        margin-right: 10px;
      }
    }
  }

  .prontuarioSubtitleContent {
    margin-left: 6px;
  }

  .prontuarioFileName {
    cursor: pointer;
    display: flex; 
    align-items: flex-end;
    margin-top: 10px;

    svg {
      color: $grey-darker2;
      font-size: 30px;
      margin-right: 5px;
    }
  }

  iframe {
    border: 0;
  }
}

.borderedContainer {
  border: 1px solid $brown-lighter;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 25px;

  .borderedContainerTitle {
    position: relative;
    top: -20px;
    height: 13px;
    margin-bottom: -13px;
    color: $brown-lighter;
    font-size: 13px;
    padding: 0 5px;
    background-color: $white;
    width: max-content;
  }
}

.scheduleActionsButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    text-transform: capitalize;
  }
}

.protocolButton {
  width: 100%;
  background-color: $blue !important;
  text-transform: capitalize !important;
}

.modal_items_body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  margin: 5px;

  input {
    padding: 7px 10px !important;
  }  
  
  .autoComplete div:first-child {
    label {
      top: -8px;
    }
    button {
      top: -4px;
    }
    div {
      padding: 4px;
    }
  }  

  .remainingQtd {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div:first-child {
      width: 200px;

      small {
        color: $grey;
      }
    }
  }

  h3 {
    align-self: center;
  }

  button {
    width: auto;
    align-self: center;
  }

  .input_qtd {
    padding: 0.76rem;
    border: 1px solid $grey;
    outline-color: $brown-lighter;
  }

  .inputs {
    padding: 10px 0 30px 0;
    width: 100%;
  }

  table {
    border-spacing: 0;
  }
  
  .tab {
    display: flex;
    flex-direction: row;
    gap: 20px;
    border-bottom: 1px solid $brown-lighter;
    color: $brown-lighter;
    text-decoration: none;
    margin-bottom: 20px;

    .element {
      cursor: pointer;
      color: $brown-lighter;
    }

    .elementActive {
      color: $brown-lighter;
      border-bottom: 3px solid $brown-lighter;
      cursor: pointer;
    }
  }

  .noItemsSelected {
    font-size: 13px;
    height: 61px;
    background-color: $grey-lighter;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 8px;
  }
}

.editorSelect {
  margin: 10px 0 !important;
}

.individualObsLabel {
  margin-top: 10px;
}

.sinaisVitais {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;

  label {
    top: -7px;
  }

  input {
    padding: 9px 10px !important;
  }
}

.uploadFileButton {
  background-color: #969696 !important;
  color: $white !important;
  text-transform: capitalize !important;

  &:hover {
    background-color: $grey-darker2 !important;
  }
}

.saveDiv {
  display: flex;
  gap: 15px;
}


.header {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  border: 1px solid $grey;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 15px 10px 10px 10px;

  .headerText {
    display: flex;
    justify-content: space-between;
  }

  .divHeaderProntuario {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    .headerProntuario {
      font-size: 22px;
    }
  }
}

.body {
  width: 100%;
  border-right: 1px solid $grey;
  border-left: 1px solid $grey;
  padding: 10px;

  .addProblem {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }

  .gridContainerListProblem {
    overflow-y: auto;
    min-height: 100px;

    .gridItemListProblem {
      display: flex;
      align-items: center;

      svg {
        width: 40px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
}

.download {
  padding: 10px 0;
  font-size: 17px;
  color: #15ab72;
  cursor: pointer;

  svg {
    margin-right: 5px;
    font-size: 18px;
  }
}

.modalAddProblem {
  width: 50%;

  .containerModalAddProblem {
    width: 100%;

    .gridModalAddProblem {
      margin-top: 5px;
    }
  }
}

.modalRemoveProblem {
  width: 40%;

  .containerModalRemoveProblem {
    width: 100%;

    h1 {
      padding: 0;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .labelDataModalRemoveProblem {
      color: $grey;
      font-size: 14px;
    }

    .inputDataModalRemoveProblem {
      display: flex;

      button {
        margin-left: 10px;
        line-height: 1.2;
      }
    }

    .btnModalRemoveProblem {
      display: flex;
      margin-top: 50px;

      button {
        background-color: #ad0303;
        line-height: 1.2;
        font-size: 13px;
        width: 180px;
        text-transform: capitalize;
      }
    }
  }
}

.modalHistoricoDiseases {
  width: 50%;

  .containerModalHistoricoDiseases {
    width: 100%;

    .cardBackHistorico {
      max-height: 40vh;
      overflow-y: auto;

      .cardModalHistoricoDiseases {
        display: flex;
        gap: 20px;
        margin-bottom: 10px;

        p {
          display: flex;
          gap: 5px;

          span svg {
            width: 20px;
            height: 20px;
          }

          .svgArrowDown {
            color: #ad0303;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.separatorTitle {
  color: $grey-darker;
  font-weight: bold;
}

.footer {
  width: 100%;
  border: 1px solid $grey;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 10px 15px 10px;

  .footerHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .footerHeaderBtns {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      align-items: center;
      button {
        pointer-events: auto;
      }
    }

    .tableMedical {
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .divTextArea {
    margin-top: 20px;
  }

  .professionalNameFooter {
    display: flex;
    justify-content: flex-end;
  }

  .gridContainer {
    .gridItems {
      display: flex;
      justify-content: center;
    }
  }

  .footerButtons {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
}

.modalHistoricoConsultas {
  width: 50%;

  .modalHistoricoHeader {
    width: 100%;
    margin-bottom: 20px;
  }

  .cardHistoricoConsultas {
    width: 100%;

    .anamnesiModalHistoricoConsulta {
      border: 1px solid #fff;
      height: 30vh;
    }
  }
}

.modalViewProtocol {
  width: 50%;

  .headerModalViewProtocolo {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 5%;

    button {
      font-size: 13px;
    }
  }

  .outsideContainerViewProtocol {
    width: 100%;
    max-height: 40vh;
    overflow-y: auto;

    .containerViewProtocol {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .contentHeaderModalViewProtocolo {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    .insideCardModalViewProtocolos {
      display: flex;
      gap: 30px;
    }
  }
}

.modalAddProtocolo {
  width: 50%;

  .AddProtocoloHeader {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .cardContainerAddProtocolo {
    width: 100%;
    display: flex;
    flex-direction: column;

    .proceduresForm {
      margin-bottom: 20px;
      align-items: center;
    }
  }
  .btnsModalAddProtocolo {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
}

.modalSolicitarInsumo {
  width: 50%;

  .gridSolicitarInsumo {
    margin-top: 5px;
  }

  .btnModalSolicitarInsumo {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}