@import 'variables.scss';

.Table {
  display: flex;
  text-align: center;

  .cabecalho {
    text-align: center;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px 20px;
    width: 100%;

    thead {
      position: inherit;
      display: table;
      width: 100%;
      table-layout: fixed;
      tr {
        th {
          color: $brown-lighter;
          border-bottom: 1px solid $brown-lighter;
          padding-bottom: 10px;
        }
      }
    }

    tbody {
      overflow-y: auto;
      display: block;

      .Aguardando {
        background: linear-gradient(to right, $yellow-lighter 3%, transparent 3%);
        border: 1px solid $yellow-lighter;
      }

      .Aprovado {
        background: linear-gradient(to right, $green-lighter 3%, transparent 3%);
        border: 1px solid $green-lighter;
      }

      .Reprovado {
        background: linear-gradient(to right, $red-lighter 3%, transparent 3%);
        border: 1px solid $red-lighter;
      }

      tr {
        height: 61px;
        background-color: $grey-lighter;
        border-radius: 8px;
        display: table;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 8px;

        td {
          margin-right: 0px !important;
        }

        td:nth-last-child(1) {
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }

        .tableCell {
          font-size: 14px;
          line-height: 1.3;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 100px;
        }
      }
    }
  }
}
