@import 'variables.scss';

.modalContainer { 
  display: flex; 

  table {
    border-spacing: 0;
  }

  &>div {
    margin-top: 10px;
    padding: 10px 0;
  }

  &>div:nth-child(2) {
    border-left: solid 1px $grey-lighter;
    margin-left: 20px;
    padding-left: 20px;
  }
}

.solicitar_compra_modal_row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  margin-top: 15px;

  .solicitar_compra_modal_autocomplete_container {
    width: 100%;

    div &:nth-child(1) {
      width: 100%;
    }
  }

  .solicitar_insumo_modal_input {
    outline-color: $brown-lighter;

    input {
      height: 30px !important;
    }
  }

  .solicitar_compra_modal_add_button {
    height: 54px;
  }
}

.solicitar_insumo_footer_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.modal_container {
  display: flex;
  flex-direction: column;
  width: 24vw;
  border-radius: 8px;
  border: 0px !important;

  button {
    &:first-child {
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  div {
    padding-bottom: 0 !important;
  }

  .modal_inner_container_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: $green;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }

  .modal_inner_container_error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: #ab1f15;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }
}

.errorMessage {
  width: 100%;

  p {
    color: $red;
    margin: 10px 0 0 10px;
    font-size: 14px;
  }
}
