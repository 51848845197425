@import 'variables.scss';

.title {
  font-size: 37px;
  font-weight: 500;
  text-align: initial;
  color: #474747;
}

.tab {
  display: flex;
  flex-direction: row;
  gap: 30px;
  border-bottom: 1px solid $brown-lighter;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: $brown-lighter;
  text-decoration: none;

  .element {
    color: $brown-lighter;
    margin-right: 20px;
  }

  .elementActive {
    color: $brown-lighter;
    border-bottom: 5px solid $brown-lighter;
    cursor: pointer;
    margin-right: 20px;
  }
}

.botoes {
  display: flex;
  position: relative;
  margin-top: 10px;

  :nth-child(2) {
    right: 0;
    position: absolute;
  }
}

.buttonNext {
  color: black;
  background: $grey-lighter;
  border-color: $grey-lighter;
  width: 170px;
}

.avancar {
  background-color: $green;
  color: white;
}

.modal_container {
  display: flex;
  flex-direction: column;
  width: 24vw;
  border-radius: 8px;
  border: 0px !important;

  button {
    &:first-child {
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  div {
    padding-bottom: 0 !important;
  }

  .modal_inner_container_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: $green;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }

  .modal_inner_container_error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: #ab1f15;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }
}
