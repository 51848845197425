@import 'variables.scss';

.container {
  & .testWrap {
    display: flex;
  }
}

.filter_input {
  width: 50% !important;
  margin-left: auto !important;
}

.pedidos_table {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  table {
    border-collapse: separate;
    border-spacing: 0px 20px;
    width: 100%;

    thead {
      tr {
        th {
          color: black;
          padding: 0 20px 0 20px;
          border-bottom: 2px solid black;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 20px 25px;
          background-color: $grey-lighter;
          img {
            width: 15px;
            height: 15px;
          }

          &:first-child {
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
          }
        }
      }
    }
  }
}

.modal {
  position: absolute;
  transform: (-50%, -50%);
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: auto;
  border: 0px !important;
  z-index: 999;
  border-radius: 8px !important;
  border: 2px solid #a9a9a982 !important;

  button {
    &:first-child {
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  div {
    padding-bottom: 0 !important;
  }

  .modal_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    .modal_top_card {
      display: flex;
      flex-direction: row;
      background-color: $grey-lighter;
      border-radius: 8px;
      padding: 30px !important;
      gap: 150px;

      .modal_top_card_left {
        display: flex;
        flex-direction: column;
        font-weight: 600;
      }

      .modal_top_card_right {
        display: flex;
        flex-direction: column;
      }
    }

    .modal_table_container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      table {
        border-collapse: separate;
        border-spacing: 0px 10px;
        width: 100%;
        margin-top: 0;

        thead {
          tr {
            th {
              color: black;
              border-bottom: 2px solid black;
              &:first-child {
                padding-left: 50px;
                padding-right: 130px;
              }
              &:last-child {
                padding-left: 130px;
                padding-right: 50px;
              }
            }
          }
        }

        tbody {
          tr {
            height: 35px;
            td {
              padding: 15px 25px;
              background-color: $grey-lighter;
              img {
                width: 15px;
                height: 15px;
              }

              &:first-child {
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
              }
            }
          }
        }
      }
    }

    .modal_orcamentos_container {
      display: flex;
      flex-direction: column;
      width: 100%;

      h1 {
        font-weight: 600;
        font-size: 16px;
      }

      .modal_orcamentos_inner_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:nth-child(even) {
          background-color: $grey-lighter;
          border-radius: 8px;
        }

        .modal_orcamentos_inner_container_left {
          display: flex;
          flex-direction: column;
          padding: 10px 25px !important;
          font-weight: 600;
        }

        .modal_orcamentos_inner_container_middle {
          display: flex;
          flex-direction: column;
          padding: 10px 0px !important;
        }

        .modal_orcamentos_inner_container_right {
          display: flex;
          margin-left: auto;
          padding: 10px !important;

          button {
            background-color: $green;
            height: 30px;
            width: 120px;
            gap: 10px;
            margin: 0;
            font-size: 12px;
            text-transform: none;
            text-wrap: nowrap;
            font-weight: 300;
          }
        }
      }

      .modal_orcamentos_status_span {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .modal_buttons_container {
      display: flex;
      width: 100%;

      .modal_button_voltar_container {
        display: flex;
        height: 40px;

        button {
          padding-left: 0;
          margin-top: 0;
        }
      }

      .modal_buttons_inner_container {
        margin-left: auto;
        display: flex;
        gap: 15px;

        button {
          &:first-child {
            background-color: $red;
          }
          background-color: $green;
          height: 40px;
          width: 100px;
          gap: 10px;
          margin: 0;
          font-size: 12px;
          text-transform: none;
          text-wrap: nowrap;
          font-weight: 500;
        }
      }
    }
  }
}
