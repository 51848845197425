@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  & .action_bar {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 8px;

    & .autocomplete {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }

  & .calendar_container {
    margin-top: 16px;
  }
}
