@import 'variables.scss';

.container {
  display: grid;
  // grid-template-columns: 50% 50%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: $brown-lighter;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 100vh;
  width: 320px;

  .conteudo {
    margin: 100px 30px 0;

    .button {
      margin-top: 10px;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 32px;
    color: $white;
  }

  p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 20px;
    color: $white;
  }
}
