@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  background-color: $brown-lighter;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 100vh;
  width: 67px;
  transition: 0.3s;
  overflow-y: scroll;

  // .navigationbar_login_tab {
  //   width: 600px;
  //   h2 {
  //     color: $white;
  //   }

  //   p {
  //     color: $white;
  //   }
  //   button {
  //     border: 1px solid $white;
  //     width: 150px;
  //   }
  // }

  .items {
    flex: 1;
    justify-content: center;
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;
    
    & div {
      cursor: pointer;
    }

    & img,
    & svg {
      flex: 1;
      min-width: 36px;
      max-width: 36px;
      width: 36px;
      height: 36px;
      padding: 12px;
      color: $white;
      transition: 0.3s;

      & path {
        fill: currentcolor;
      }
    }
  }

  .links {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: $white;

    &:hover {
      background-color: $white;
      border-radius: 8px;
      transition: 0.3s;
    }

    &:hover img,
    &:hover svg {
      color: $brown-lighter;
      border-radius: 8px;
      transition: 0.3s;

      & path {
        fill: currentcolor;
      }
    }

    & > div {
      width: 60px;
      height: 60px;
    }

    & span {
      font-size: 0;
      transition: 0.3s;
    }
  }

  .itemChild {
    display: flex;
    flex-direction: column;
    height: 60px;
    transition: 0.3s;

    .childItems {
      visibility: hidden;
      display: flex;
      align-items: center;
      height: 0px;
      margin: 5px 0px 0px 7px;
      transition: 0.3s;

      & svg {
        width: 0px;
        height: 0px;
        transition: 0.3s;
      }
      & span {
        font-size: 14px !important;
        transition: 0.3s;
      }
    }
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }

  .arrow {
    padding: 20px 0;
    border-radius: 8px;

    & svg {
      cursor: pointer;
      color: $white;
      width: 36px;
      height: 36px;
      padding: 12px;
      transition: 0.3s;
    }

    & button {
      background-color: $brown-lighter;
      border: none;

      &:hover svg {
        color: $brown-lighter;

        & path:first-child {
          fill: none;
        }
      }
    }
  }
}

.containerAlt {
  width: 250px;

  & .links {
    & span {
      font-size: 16px;
      color: $white;
    }

    &:hover span {
      color: $brown-lighter;
    }
  }

  & .linkActive {
    color: $brown-lighter;

    & span {
      font-size: 16px;
      color: $brown-lighter;
    }
  }

  & .itemChild {
    height: auto !important;
    transition: 0.3s;
  }

  & .childItems {
    visibility: visible !important;
    height: 30px !important;
    transition: 0.3s;

    & svg {
      margin-top: 4px;
      width: 17px !important;
      height: 17px !important;
      transition: 0.3s;
    }

    & span {
      font-size: 16px !important;
      transition: 0.3s;
      color: $white !important;
    }

    &:hover span {
      background-color: $white !important;
      color: $brown-lighter !important;
      padding: 3px;
      border-radius: 8px;
    }
  }

  & .arrow svg {
    transform: rotate(180deg);
  }
}
