.modalMedi {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    padding-top: 10px;
    font-size: 32px;
  }

  input {
    border-radius: 8px;
    padding-left: 10px;
    width: 400px;
    height: 50px;
  }

  .btnMedi {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
}