@import 'variables.scss';

.tableActions {
  display: flex;
  place-content: center;
  font-size: 24px;
  color: $brown-lighter;
  gap: 6px;

  svg {
    cursor: pointer;
    &:first-child {
      font-size: 22px;
      margin-top: 1px;
    }
  }
}

.colaboradoresPage {
  .tdUser {
    padding-left: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.modal_container {
  display: flex;
  flex-direction: column;
  width: 24vw;
  border-radius: 8px;
  border: 0px !important;

  button {
    &:first-child {
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  div {
    padding-bottom: 0 !important;
  }

  .modal_inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: $green;
      font-weight: 400;
    }

    span {
      text-align: center;
    }

    button {
      background-color: $green;
      height: 30px;
      width: 12vw;
    }
  }
}
