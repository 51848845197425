.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .button {
    display: flex;
  }
  .icon {
    width: 38px;
    height: 60px;
  }
}
.total {
  color: #15ab72;
}
.planGreen {
  color: #15ab72;
}
.planRed {
  color: red;
}

.no_clinic_selected {
  display: flex;
  padding: 250px;

  p {
    font-size: 24px;
    text-align: center;
  }

  svg {
    width: 50px;
    height: 50px;
    align-self: center;
  }
}
