.editButton {
  display: flex;
  gap: 5px;
  justify-content: center;

  .pen {
    width: 20px;
    height: 20px;
  }

  svg {
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
}
