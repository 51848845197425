@import 'variables.scss';

.validation_error {
  margin-top: 15px;
  color: $red;
}

.pen {
  color: $black !important;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
