@import 'variables.scss';

.homemodules {
  margin-top: 20px;
  width: 100%;

  .home {
    margin-bottom: 10px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    gap: 5px;

    & img,
    & svg {
      min-width: 36px;
      max-width: 36px;
      color: $white;
      width: 30px;
      height: 30px;
      padding: 12px 8px 12px 12px;

      & path {
        fill: currentcolor;
      }
    }
  }

  .links {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: $white;
    transition: 0.3s;

    &:hover {
      background-color: $white;
    }

    &:hover,
    &:hover img,
    &:hover svg {
      color: $brown-lighter;
      cursor: pointer;
      & path {
        fill: currentcolor;
      }
    }

    & > div {
      width: 60px;
      height: 60px;
    }

    & > span {
      transition: 0.3s;
    }
  }

  .linkActive {
    background-color: $white;
    color: $brown-lighter;

    svg {
      color: $brown-lighter;
    }
  }
}
