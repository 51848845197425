.container {
  margin: 0 auto;
  width: 100%;
}

.conteudo {
  margin: 5% auto 0 auto;
  width: 815px;

  h1 {
    font-size: 37px;
    font-weight: 500;
    padding-top: 50px;
    text-align: initial;
  }
}
