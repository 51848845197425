@import 'variables.scss';

.container {
  & .card {
    border-radius: 8px;
    margin: 10px 0;
    padding: 20px;
    background-color: $grey-lighter;
  }
}
