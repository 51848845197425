
.lista {
  max-height: 50%;

  .inputButton {
    display: flex;
    align-items: center;

    .input {
      width: 414px;
    }

    form {
      display: flex;

      .button {
        margin-left: 10px;
      }
    }
  }

  .editButton {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    svg {
      cursor: pointer;
      width: 23px;
      height: 23px;
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input {
      margin-bottom: 20px;
      text-align: center;
      margin-top: 10px;
      width: 398px;
    }

    .text {
      font-weight: lighter;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
}

.footer {
  display: flex;
}

.modal_container {
  display: flex;
  flex-direction: column;
  width: 24vw;
  border-radius: 8px;
  border: 0px !important;

  button {
    margin-bottom: 20px;
  }

  div {
    gap: 10px;
    padding-bottom: 0px !important;
  }

  .modal_inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    span {
      text-align: center;
    }

    button {
      height: 30px;
      width: 12vw;
    }
  }
}
