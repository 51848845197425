@import 'variables.scss';

.editButton {
  display: flex;
  gap: 20px;
  justify-content: center;

  svg {
    width: 23px;
    height: 23px;
    cursor: pointer;
  }

  .approve {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.appovedBtn {
  display: flex;
  gap: 20px;
  justify-content: center;

  .download {
    cursor: pointer;
  }

  svg {
    width: 23px;
    height: 23px;
  }
}

.btnAdd {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 2%;
}

.gridUpload {
  display: flex;
  gap: 10px;
  align-items: center;
}

.positiveValue {
  color: $green;
}

.negativeValue {
  color: $red;
}
