@import 'variables.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.consultorio_select_global_select {
  border-radius: 50px !important;

  div {
    color: $brown;
    background-color: rgb(255 255 255 / 12%) !important;
  }
  label {
    color: $brown !important;
  }
}
