@import 'variables.scss';

.TextArea {
  width: 100%;
  resize: none;
  max-height: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  overflow-y: scroll;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 2px $grey-lighter;

  &:hover {
    border-color: #c5aa71;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
}
