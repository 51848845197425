@import 'variables.scss';

.card {
  margin: 15px 0 15px 0;

  .gridContainer {
    &>div:first-child {
      border-right: solid 1px $grey;
    }
    &>div {
      margin-top: 15px;
      padding: 0 20px 15px 20px;
      align-content: start;
    }
    table {
      border-spacing: 0;
      tbody tr {
        background-color: $grey-lighter2;
      }
    }
  }

  .inputNumber {
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
    /* Estilos adicionais para compatibilidade com diferentes navegadores */
    input {
      -moz-appearance: textfield !important;
    }
  }
}
