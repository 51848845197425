@import 'variables.scss';

.totalWithDiscount {
  font-weight: 500;
  font-size: 15px;
  color: $green;
}

.proceValueInput {
  width: 200px;
  margin: auto;
}