.container {
  .card {
    padding: 30px !important;
    .dados {

      p {
        margin-bottom: 10px;
      }
    }
    .info {
      margin-top: 20px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
