@import 'variables.scss';

.container {
  .card {
    margin-bottom: 20px;

    .form {
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: $grey-lighter;
    }
  }

  .checkboxes {
    margin-top: 10px;
    margin-bottom: 20px;
    .checkbox {
      display: flex;
      align-items: center;
    }
  }
}
