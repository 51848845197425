
.footer {
  display: flex;
  justify-content: flex-end;
}

.header {
  display: flex;
  justify-content: space-between;
}

.editButton {
  display: flex;
  gap: 5px;
  justify-content: center;

  .pen {
    width: 20px;
    height: 20px;
  }

  svg {
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
}

.containerAddUnit {
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }

  .qtd {
    display: flex;
    justify-content: center;
    font-size: 20px;
    gap: 20px;
    margin-bottom: 20px;

    .borderNumber {
      border: 1px solid #000;
      padding: 10px 20px 10px 20px;
    }

    p {
      display: flex;
      align-items: center;
    }
  }

  .contBtnAddUnit {
    display: flex;
    justify-content: center;
    gap: 5px;

    .btnAddUnit {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }

    .btnMinusAddUnit {
      transform: rotate(180deg);
      cursor: pointer;
      height: 40px;
      width: 40px;
    }
  }
}

.containerViewPedido {
  .contentView {
    p {
      margin-bottom: 10px;
    }
  }
  .contBtnViewPedido {
    display: flex;
    .saldo {
      width: 40%;
    }
  }

  .btnAdd {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .btnMinus {
    transform: rotate(180deg);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .btnViewPedido {
    margin-top: 5%;
  }
}

.btnAddEstoque {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 2%;
}
