@import 'variables.scss';

.header_icons_container {
  display: flex;
  gap: 5px;

  .header_icon_dollar {
    color: $green;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .header_icon_cart {
    cursor: pointer;
    color: $brown;
    height: 30px;
    width: 30px;
  }
  .header_icon_calendar {
    border-right: 1px solid $black;
    color: $black;
    height: 30px;
    width: 30px;
    padding-right: 10px;
    cursor: pointer;
  }
}
