.notificationCount {
  width: 15px !important;
  height: 20px !important;
  border: 1px solid #000;
  background-color: #ec5353;
  border-radius: 100%;
  font-size: 14px;
  padding-left: 5px;
  font-weight: 500;
  margin-left: 8px;
}