.container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  & label {
    font-size: 16px;
    font-weight: 300;
  }

  .input {
    &input,
    & input {
      height: 23px;
      padding-top: 12px;
      padding-bottom: 13px;
      background-color: white;
      border-radius: 8px;
    }
    // border-radius: 8px;

    & :global(.MuiFormHelperText-root) {
      margin-top: 0;
    }
    & :global(.MuiInputBase-root.Mui-disabled) {
      background: #ababab;
    }
  }
}
