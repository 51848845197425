@import 'variables.scss';

.container {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  border-bottom: 1px solid $brown-lighter;
  height: 70px;

  .calendarAndNotification {
    display: flex;
    align-items: center;
    padding-right: 10px;

    .cart {
      color: #000;
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .pedidosPopUp {
      display: flex;
      margin-left: 10px;

      svg {
        width: 31px;
        height: 31px;
      }
    }
  }

  .headerLeft {
    position: absolute;
    left: -200px; /* Start offscreen to the left */
    top: calc(50% - 23px);
    display: flex;
    transition: left 0.5s ease; /* Use ease or any other easing function you prefer */

    &.visible {
      left: 10px; /* Move to the desired position */
    }

    .header_home_container {
      width: 100%;
      display: flex;
      align-items: center;
      transition: 0.3s;
      background-color: $brown;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: $brown-lighter;
      }

      .header_home_icon {
        border-radius: 8px;
        min-width: 36px;
        max-width: 36px;
        color: $white;
        width: 30px;
        height: 30px;
        padding: 8px;
        cursor: pointer;
      }

      .header_home_module {
        display: flex;
        font-size: 18px;
        padding-right: 15px;
        border-radius: 8px;
        color: $white;
        height: 45px;
        align-items: center;
      }
    }
  }

  .headerCenter {
    position: absolute;
    top: calc(50% - 23px);
    display: flex;
  }

  .headerRight {
    position: absolute;
    right: 10px;
    top: calc(50% - 27.5px);
    display: flex;
  }

  .user {
    display: flex;
    margin-left: auto;
    cursor: pointer;
  }
}
