@import 'variables.scss';

.card {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  margin-top: 30px;

  .cardContainer {
    .headerPatient {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .procedimentoContainer {
      margin-top: 2%;
      margin-bottom: 2%;

      .headerProcedures {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .headerInsideProcedures {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1% 0 1% 5%;

        .priceAndBtn {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .buttonsAgendar {
    width: 80px;
    height: 10px;
  }
}

.modal {
  .containerModal {
    width: 100%;

    .modalHeader {
      display: flex;
      justify-content: space-between;
    }

    .grid {
      margin-top: 1%;
    }

    .cardContainerTable {
      overflow-y: scroll;
      padding: 0;
      margin: 20px 0 20px 0;
    }

    .footerModalPay {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;
    }
  }
}
  
.paymentButton {
  margin-top: 15px !important;
  display: flex !important;
  margin-left: auto !important;
}

.scheduleIsPayed {
  color: $green;
  font-weight: 500;
  margin-right: 51px;
}