@import 'variables.scss';

.divContainer {
  margin-top: 5px !important;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 8px;
  gap: 20px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .divObs {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    small {
      line-height: 1.66;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.75rem;
      margin-left: 14px;
    }
  }

  .divGender {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;

    span {
      padding: 10px 5px 10px 15px;
    }
  }

  .divData {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    width: 100%;
    gap: 1.33%;
    margin: 10px 0 20px 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.textA {
  width: 100%;
  resize: none;
  height: 170px;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  overflow-y: scroll;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 2px 2px $grey-lighter;

  &:hover {
    border-color: #c5aa71;
  }
}
