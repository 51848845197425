@import 'variables.scss';

.container {
  margin: 0 auto;
  width: 100%;
}

.conteudo {
  margin: 0 auto 0 auto;
  width: 815px;
}

.navi {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid $brown-lighter;
  margin: 40px 0px 40px 0px;
  color: $brown-lighter;

  div:hover {
    border-bottom: 5px solid $brown-lighter;
    cursor: pointer;
  }
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .card {
    height: 348px;
    width: 299px;
    border: 1px solid $brown-lighter;
    padding: 20px;
    border-radius: 8px;

    .header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $brown-lighter;

      .titulo {
        margin-bottom: 10px;
      }

      p {
        font-weight: 600;
        font-size: 12px;
      }

      span {
        font-weight: 275;
        font-size: 10px;
      }

      .price {
        p {
          font-weight: 600;
          font-size: 24px;
        }
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      tr {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .tabela {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .ico {
        color: green;
      }
    }
  }
}
