.card {
  display: grid;
  grid-template-columns: 50% 50%;

  h2 {
    display: none;
  }
}

.opa {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .selectContainer {
    .select {
      width: 200px !important;
    }
  }

  .containerFiltro {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 125px;
  }
}
