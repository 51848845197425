@import 'variables.scss';

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(192, 191, 191, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 100vh;
}

.container {
  max-width: 65%;
  background-color: $white;
  border-radius: 8px;

  .button_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .button {
      display: flex;
      justify-content: flex-end;
      border: none;
      background-color: $white;
      font-size: 22px;
      margin: 6px;

      svg {
        cursor: pointer;
      }
    }
  }

  .body {
    padding: 0 50px 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
