.container {
    & .topBar{
        margin-top: 20px;
    }

    & .timeContainer {
        display: flex;
    }

    & .timeCol {
        margin: 10px;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    & .timeInput {
        text-align: center;
    }

    & .dayText {
        margin: 10px 0;
        text-align: center;
    }

    & .stopText {
        margin: 30px 0 30px 0;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #4B4B4D;
    }
}