@import 'variables.scss';

.container {
  margin: 0 auto;
  width: 100%;
}

.conteudo {
  margin: 10% auto 0 auto;
  width: 690px;

  p {
    font-size: 37px;
    font-weight: 500;
    padding-top: 50px;
    text-align: initial;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  gap: 20px;
  background-color: $grey-lighter;

  input {
    width: 100%;
    height: 95px;
    border: none;
    padding-left: 10px;
    background-color: $grey-lighter;
    color: $black;
    font-weight: 400;
    font-size: 24px;
  }

  img {
    padding-left: 25px;
  }
}

.spans {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-device-width: 1350px) and (max-device-width: 1499px) {
  .conteudo {
    margin: 10% auto 0 auto;
    // width: 690px;
  }
}
