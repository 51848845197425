.container {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .footer{
    display: flex;
    .value{
      margin-left: 7px;
      color: #15AB72
    }
  }
}
