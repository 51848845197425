@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  //   margin-bottom: 10px ;

  .header {
    text-align: center;
    margin-bottom: 15px;

    .title {
      font-size: 18px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    width: 800px;

    .patients {
      display: flex;
      align-items: flex-end;

      .date_container {
        display: flex;
        flex-direction: column;
        width: 24%;
  
        span {
          font-size: 12px;
        }
      }

      .patientsAutocomplete {
        width: 75%;
        margin-left: 1%;
      }
    }

    .repeat {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .repeatInput {
        width: 40px;
        margin: 0 7px;

        input {
          padding: 5px 10px;
          font-size: 14px;
        }
        
        legend {
          width: 0;
        }
      }

      span {
        font-size: 14px;
        margin-top: 2px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-left: 8px !important;
        }
      }
    }

    .table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        width: 300px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        text-align: end;
        font-style: italic;
        width: 440px;
        
        a {
          color: $brown;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          padding-right: 5px;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;

    button {
      font-size: 12px;
      width: 108px;
      height: 50px;
      padding: 0;
      line-height: 1.2;
    }

    .button_add {
      background-color: $green;
      margin-left: auto;
    }

    .button_close {
      background-color: $red;
      margin-left: auto;
    }

    .button_arrived {
      background-color: $green;
      margin-left: auto;
    }

    .button_cancel {
      background-color: $red;
    }

    .button_didnotattend {
      background-color: $blue;
    }

    .button_edit {
      background-color: $brown-lighter;
    }

    .button_reschedule {
      background-color: $yellow;
    }   
  }
  
  .footer_error {
    font-size: 13px;
    margin-top: 20px;
    color: $red;
  }
}
