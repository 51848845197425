@import 'variables.scss';

.container {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: bold;
  line-height: 15px;
}

.inputContainer {
  display: flex;
  box-sizing: border-box;
  position: relative;
  // margin-top: 5px;
  //padding-bottom: 5px;

  &.hasIconLeft {
    svg {
      @extend .icon;

      left: 15px;
    }
  }

  &.hasIconRight {
    svg {
      @extend .icon;
      right: 15px;
    }
  }

  .input {
    padding: 0.575rem 0.75rem;
    width: 100%;
    line-height: 1.5;
    border-radius: 8px;
    border: 1px solid black;
    background-color: white;
    color: black;
    font-size: 0.9rem;
    transition:
      border-color 300ms,
      background-color 300ms,
      box-shadow 200ms;

    & :global(.MuiFormHelperText-root) {
      margin-top: 0;
    }
    & :global(.MuiInputBase-root.Mui-disabled) {
      background: $grey;
    }

    &.hasIconLeft {
      padding-left: 38px;
    }

    &.hasIconRight {
      padding-right: 38px;
    }

    &.hasError {
      border-color: transparent;
      background-color: transparent;
      outline-color: transparent;
    }
  }

  ::placeholder {
    color: black;
  }
}

.icon {
  position: absolute;
  top: 13px;
  height: auto;
  width: 15px;
}
