.editButton {
  display: flex;
  gap: 10px;
  justify-content: center;

  svg {
    width: 23px;
    height: 23px;
  }

  .approve {
    cursor: pointer;
  }

  .approvedEdit {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.btnAdd {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 2%;
}

.gridUpload {
  display: flex;
  gap: 10px;
  align-items: center;
}

.modal {
  width: 100%;

  .grid {
    margin-top: 10px;
  }
}
