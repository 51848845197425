@import 'variables.scss';

.autocomplete {
  border-radius: 8px;

  input {
    height: 15px;
  }

  .textfield {
    border-radius: 8px;
  }
}
