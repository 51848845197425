.container {
  max-width: 100%;

  .gridDespesa {
    flex-wrap: nowrap !important;
    display: flex;
    gap: 20px;
    margin-top: 30px;

    .botaoAdd {
      display: flex;
      align-items: flex-end;
      margin-bottom: 7px;
    }
  }

  .editButton {
    font-size: 24px;
    cursor: pointer;
  }

  .formulario {
    margin: 10px 0 10px 0;
    width: 100%;
  }

  .filters {
    padding: 10px 0 20px 0;

    .estiloDespesa {
      color: red;
    }

    .estiloReceita {
      color: green;
    }
  }

  .gridReceita {
    margin-top: 30px;
  }
}
