.container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  
  & label {
    font-size: 16px;
    font-weight: 300;
  }

  & .input {
    :global( .MuiSelect-select){
      padding-top: 11px;
      padding-bottom: 13px;
      background-color: white;
    }
  }
}
