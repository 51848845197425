@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;

  .tabs_container {
    display: flex;
    align-items: center;
    height: 50px;
    width: auto;
    border-bottom: 2px solid $brown;
    margin: 0 5px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 20%;
    }

    .default_tab {
      color: $brown-lighter;
    }

    .active_tab {
      color: $brown-lighter;
      font-weight: bold;
      border-bottom: 4px solid #c5aa71;
      // background: linear-gradient(#ffffff, #f3ebda);
      // border-radius: 50px;
      cursor: pointer;
    }
  }

  .tabs_container_edit {
    display: flex;
    align-items: center;
    height: 50px;
    width: auto;
    border-bottom: 2px solid $brown;
    margin: 0 5px;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 20%;
    }

    .default_tab {
      color: $brown-lighter;
      border-radius: 50px;

      &:hover {
        background-color: #f5ecd8;
      }
    }

    .active_tab {
      color: $brown-lighter;
      font-weight: bold;
      border-bottom: 4px solid #c5aa71;
      cursor: pointer;
    }
  }

  .buttons_container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .next_button {
      background-color: $green;
    }

    .navigate_buttons {
      display: flex;
      gap: 15px;

      .next_button {
        background-color: $green;
      }

      .previous_button {
        background-color: $green;
      }
    }
  }

  // modal
  .body_success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: $green;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }

  .body_error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    h1 {
      font-size: 20px;
      color: #ab1f15;
      font-weight: 400;
    }

    span {
      text-align: center;
    }
  }
}
