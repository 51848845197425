@import 'variables.scss';

.lista {
  .lista_solicitacoes_no_orders {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }

  .solicitacoes_icons_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;

    .solicitacoes_icon_view {
      align-self: center;
      color: $brown-lighter;
      cursor: pointer;
      height: 17px;
      width: 20px;
    }

    .solicitacoes_icon_edit {
      align-self: center;
      color: $brown-lighter;
      cursor: pointer;
      height: 22px;
      width: 18px;
    }

    .solicitacoes_icon_delete {
      align-self: center;
      color: $red;
      cursor: pointer;
      height: 22px;
      width: 22px;
    }
  }

  .solicitacoes_icon_view {
    align-self: center;
    color: $brown-lighter;
    cursor: pointer;
    height: 17px;
    width: 20px;
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input {
      margin-bottom: 20px;
      text-align: center;
      margin-top: 10px;
      width: 398px;
    }

    .text {
      font-weight: lighter;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .modalStatus {
    display: flex;
    flex-direction: column;
    width: 24vw;
    border-radius: 8px;
    border: 0px !important;

    div {
      padding-bottom: 0 !important;
    }

    .modal_inner_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      padding-bottom: 25px !important;

      h3 {
        text-align: center;
      }

      .modal_inner_container_success {
        color: $green;
        text-align: center;
      }

      .modal_inner_container_fail {
        color: $red;
        text-align: center;
      }
    }
  }
}
