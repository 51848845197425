@import 'variables.scss';

.navi {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid $brown-lighter;
  margin: 40px 0px 40px 0px;
  color: $brown-lighter;
  text-decoration: none;
}
