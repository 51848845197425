@import 'variables.scss';

.container {
  position: relative;
  top: -30px;
  margin-bottom: -30px;
}

.options {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.option {
  width: 150px;
  padding: 0 50px;
  text-wrap: nowrap;
  cursor: pointer;
  border: solid 1px $brown;
  font-size: 15px;
  color: $brown;
  border-radius: 5px;
  height: 28px;
  background-color: $white;  
  display: flex;
  align-items: center;
  place-content: center;
}

.selectedOption {
  height: 32px;
  font-size: 17px;
  font-weight: 700;
  color: $white;
  background-color: $brown;  
}

.bordered {
  height: 1px;
  width: 100%;
  background-color: $brown-lighter;
}