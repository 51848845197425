@import 'variables.scss';

.tab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid $brown-lighter;
  margin: 10px 0px 30px 0px;
  color: $brown-lighter;
  text-decoration: none;

  .element {
    cursor: pointer;
    color: $brown-lighter;
  }

  .elementActive {
    color: $brown-lighter;
    border-bottom: 5px solid $brown-lighter;
    cursor: pointer;
  }
}

.solicitacao_compras_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.no_clinic_selected {
  display: flex;
  padding: 250px;

  p {
    font-size: 24px;
    text-align: center;
  }

  svg {
    width: 50px;
    height: 50px;
    align-self: center;
  }
}
