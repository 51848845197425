@import 'variables.scss';

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.conteudo {
  width: 690px;

  p {
    font-size: 37px;
    font-weight: 500;
    text-align: initial;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  height: 95px;
  gap: 20px;
  background-color: $grey-lighter;

  .eye_password {
    padding: 0 30px 0 15px;
    width: 50px;
    height: 50px;
    color: #15ab72;

    cursor: pointer;
    &:hover {
      opacity: 1 !important;
    }
  }

  input {
    width: 100%;
    height: 95px;
    border: none;
    padding-left: 10px;
    background-color: $grey-lighter;
    color: $black;
    font-weight: 400;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }

  img {
    padding-left: 25px;
  }
}

.spans {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.loginButton {
  margin-top: 20px;
  float: right;
}

.box {
  display: flex;
  height: 189px;
  background-color: $grey-lighter;
  border-radius: 8px;
  align-items: center;

  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    padding: 20px;
  }
}

.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.conteudoForget {
  margin: 17% auto 0 auto;
  width: 670px;

  p {
    font-size: 37px;
    font-weight: 500;
    padding-top: 50px;
    text-align: initial;
  }

  .boxForget {
    display: flex;
    height: 189px;
    background-color: $grey-lighter;
    border-radius: 8px;
    align-items: center;

    p {
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      padding: 20px;
    }
  }
  .btnForget {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}

@media only screen and (min-device-width: 1350px) and (max-device-width: 1499px) {
  .conteudo {
    margin: 10% auto 0 auto;
    // width: 690px;
  }
}
