@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;

  .form_container {
    display: flex;
    justify-content: space-around;
  }

  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
  }

  .error {
    display: flex;
    font-size: 12px;
    color: $red;
  }
}
