@import 'variables.scss';

.modal_adicionar_parcela {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 0px !important;

  h2 {
    margin-bottom: 25px;
  }
}

.modal_adicionar_parcela_button {
  width: 100%;
}

.validation_error {
  margin-top: 15px;
  color: $red;
}
