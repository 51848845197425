@import 'variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  background-color: $grey-lighter;
  border-radius: 8px;

  .title {
    font-size: 28px;
    font-weight: 400;
    text-align: initial;
    color: #474747;
    padding-bottom: 15px;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px;
  }

  .profile_picture_container {
    display: flex;
    flex-direction: column;
    padding: 50px;

    span {
      display: flex;
      align-self: center;
      width: max-content;
      margin-top: 10px;
      font-size: 12px;
      cursor: pointer;
    }

    .picture {
      border: 3px solid $white;
      cursor: pointer;
    }
  }
}
