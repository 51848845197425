.header {
  display: flex;
  justify-content: space-between;
}

.editButton {
  display: flex;
  gap: 5px;
  justify-content: center;

  svg {
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
}

.modal_container {
  display: flex;
  flex-direction: column;
  width: 24vw;
  border-radius: 8px;
  border: 0px !important;

  button {
    margin-bottom: 20px;
  }

  div {
    gap: 10px;
    padding-bottom: 0px !important;
  }

  .modal_inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px !important;

    span {
      text-align: center;
    }

    button {
      height: 30px;
      width: 12vw;
    }
  }
}
