* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  scrollbar-width: thin;
}

body {
  background-color: #fff;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #4b4b4d;
  border-radius: 20px;
  border: 1px solid transparent;
}

.fc .fc-toolbar-title {
  text-transform: capitalize;
}
