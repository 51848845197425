@import 'variables.scss';

.container {
  width: 60%;
}

.busca {
  display: flex;

  .search {
    display: flex;
    gap: 10px;

    .input {
      height: 48px;
      width: 300px;
      border: 1px solid $brown-lighter;
      border-radius: 8px;
      padding-left: 10px;
      outline: none;
    }
  }
}

.tabelas {
  display: grid;
  grid-template-columns: 436px 436px;
  gap: 40px;

  table {
    border-spacing: 0 !important;
    margin-bottom: 20px;

    thead {
      margin: 20px 0 10px 0;
    }

    th {
      padding-bottom: 0 !important;
      padding-left: 10px;
      text-align: start;
      font-weight: 400;
    }

    .tableItem {
      background-color: $grey-lighter;
      border-radius: 8px;
      padding: 15px 0;
      margin-bottom: 6px;
    }

    .tableItemHover {
      &:hover {
        cursor: pointer;
        background-color: #e2e2e2;
      }
    }

    tr {
      height: auto !important;
      background-color: transparent !important;
      margin-bottom: 0 !important;
    }
  }
}

.noResults {
  height: 80px;
  font-size: small;
  text-align: center;
  font-style: italic;
}

.buttonMedi {
  display: flex;
  justify-content: space-between;
}

.buttonContr {
  display: flex;
  justify-content: flex-end;
}
