@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px;

  .title {
    font-size: 28px !important;
    font-weight: 400 !important;
    text-align: initial;
    color: $grey-darker;
    margin-bottom: 15px;
  }
}
