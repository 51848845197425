
.container {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 16px;
    margin-top: 20px;
  
  .selectContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;

    .selectGroup {
      & > .MuiGrid-item {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.inputValue{
  color: green !important;
  background-color: yellow;
}