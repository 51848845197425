@import 'variables.scss';

.modalContr {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 400px;
  gap: 30px;

  h1 {
    padding-top: 10px;
    font-size: 32px;
  }

  .divInput {
    height: 146px;
    width: 390px;
    border: 1px solid $brown-lighter;
    border-radius: 8px;
    padding-left: 10px;

    textarea {
      width: 98%;
      height: 90%;
      outline: none;
      resize: none;
      border: none;
      display: block;
      padding-top: 5px;
    }
  }

  .button {
    display: flex;
    justify-content: space-between;

    .btn {
      cursor: pointer;
      border: none;
      background-color: $white;
      color: rgba(21, 171, 114, 1);
      display: flex;
      align-items: center;

      svg {
        transform: rotate(180deg);
        width: 25px;
        height: 25px;
      }
    }
  }
}
