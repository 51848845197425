@import 'variables.scss';

.checkbox_container {
  border-radius: 8px;
  color: $brown-lighter;
  font-size: 0.875rem;
  height: auto;
  max-height: 400px;
  overflow-y: auto;

  .checkbox_inner_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .checkbox_container {
      display: flex;
      flex-direction: row;

      .checkbox_icon_container {
        display: flex;

        .checkbox_selectable {
          display: flex;
          width: 24px;
          height: 24px;
          background-color: white;
          border-radius: 8px;
          border: 1px solid #737373;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          svg {
            display: none;
          }
        }

        .checkbox_selectable_active {
          display: flex;
          width: 24px;
          height: 24px;
          background-color: white;
          border-radius: 8px;
          border: 1px solid #15ab72;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          svg {
            display: flex;
            color: $green;
          }
        }
      }

      .checkbox_label_container {
        display: flex;
        align-items: center;

        .checkbox_label {
          color: $black;
          margin-left: 15px;
        }
      }
    }
    .checkbox_children_container {
      display: flex;

      .checkbox_child_container {
        display: flex;
        margin-top: 5px;

        .checkbox_child_arrow {
          color: $green;
          margin-left: 5px;
          height: 25px;
          width: 25px;
        }

        .checkbox_child {
          display: flex;
          align-items: center;
          margin-left: 10px;
          width: 350px;
        }
      }
    }
  }
}

.checkbox_single_container {
  .checkbox_container {
    display: flex;
    flex-direction: row;

    .checkbox_icon_container {
      display: flex;

      .checkbox_selectable {
        display: flex;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #737373;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        svg {
          display: none;
        }
      }

      .checkbox_selectable_active {
        display: flex;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #15ab72;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        svg {
          display: flex;
          color: $green;
        }
      }
    }

    .checkbox_label_container {
      display: flex;
      align-items: center;

      .checkbox_label {
        color: $black;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
