.card{
    width: 440px;
    // height: 260px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    // margin-right: 40px;
    border: 3px solid #F2F2F2;
    text-align: center;

    .conteudo{
        margin-top: 20px;

        p{
            font-size: 26px;
            margin-bottom: 6px;
            color: #737373;
        }

        img, svg{
            width: 56px;
            height: 56px;
            filter: invert(68%) sepia(14%) saturate(944%) hue-rotate(3deg) brightness(99%) contrast(91%);
        }
    }

    .botoes{
        width: 90% ;
        border-top: 2px solid #C5AA71;
        margin: 0 auto;
        padding-top: 20px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center; /* Alinhamento horizontal */
        align-items: center; /* Alinhamento vertical */

        .botao{
            margin-right: 13px;
        }
    }

    .bottom{
        color: #737373;
    }

    &:hover{
        background-color: #F2F2F2;
    }
}



@media only screen and (max-device-width: 1499px) {
    .card
    {
        width: 387px;
        // height: 255px;
        // margin-bottom: 25px;
    }
}


@media only screen and (min-device-width: 1500px){
    .card
    {
        width: 425px;
        // margin-bottom: 25px;
    }
}


@media only screen and (max-device-width: 1440px) {
   
    .card{
        width: 350px;
        // height: 260px;
        margin-bottom: 25px;
    }

}
