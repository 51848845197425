.container {
  margin: 0 auto 0 auto;
  width: 100%;

  .conteudo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
  }
}
