@import 'variables.scss';

.details {
  display: flex;
  gap: 30px;

  .receita {
    display: flex;
    p {
      margin-left: 10px;
      color: $green;
    }
  }
  .despesa {
    display: flex;

    p {
      margin-left: 10px;
      color: $red;
    }
  }
  .result {
    display: flex;

    p {
      margin-left: 10px;

      color: $green;
    }
  }
}

.btnExport {
  margin-right: 20px;
}

.header {
  .title {
    display: flex;
    justify-content: space-between;

    .icon {
      svg {
        width: 40px;
        height: 20px;
      }
    }
  }

  .btn {
    display: flex;
    gap: 20px;
  }

  .calendar {
    display: flex;
    justify-content: space-between;

    .inputDatePicker {
      display: flex;
      gap: 10px;
    }
  }
}

.total {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 10px 15px 30px 15px;

  .fluxo_caixa_income_container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 15px;

    .income {
      display: flex;
      gap: 5px;
      span {
        color: green;
      }
    }
    .expense {
      display: flex;
      gap: 5px;
      span {
        color: red;
      }
    }
    .balance {
      display: flex;
      gap: 5px;
      span {
        color: green;
      }
    }
  }
}

.fluxo_caixa_delete_icon {
  align-self: center;
  color: $red;
  cursor: pointer;
  height: 22px;
  width: 22px;
}

.fluxo_caixa_dados_bancarios {
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
  }
}

.modalDelete {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.fluxo_caixa_bank_info {
  display: flex;
  gap: 10px;

  .fluxo_caixa_bank_name {
    color: $black;
  }

  .fluxo_caixa_bank_agency {
    color: $grey;
    border-left: 1px solid $black;
    padding-left: 15px;
  }
}
