@import 'variables.scss';

.gridContainer {
  width: 400px;
  padding: 16px 16px 20px 10px !important;
  margin: 10px !important;
  background-color: $grey-lighter;
  padding-bottom: 20px;
  border-radius: 8px;

  .modal {
    display: flex;
    align-items: center;

    .button {
      margin-left: 8px;
    }
  }

  .confirmButton {
    display: flex;
    justify-content: center;
  }
}
