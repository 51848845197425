@import 'variables.scss';

.container {
  .card {
    margin-bottom: 20px;

    .formulario {
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: $grey-lighter;
    }
  }
}
