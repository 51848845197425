@import 'variables.scss';

.card {
  background-color: $grey-lighter;
  border-radius: 8px;
  padding: 15px 30px 15px 30px;
  height: 256px;
}

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $brown-lighter;
}

.titulo {
  padding-bottom: 10px;

  h1 {
    font-weight: 400;
    font-size: 28px;
    padding-top: 0px !important;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }
}

.plano {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
}

.renovacao {
  p {
    font-weight: 300;
  }

  span {
    font-weight: 500;
  }
}

.cancel {
  span {
    font-weight: 500;
    font-size: 12px;
    color: red;
    cursor: pointer;
  }

  .cancelModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-weight: 500;
      font-size: 25px;
    }

    p {
      font-size: 20px;
      font-weight: 275;
      line-height: 24px;
    }
  }
}

.cartao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
}

.dados {
  p {
    font-weight: 300;
  }

  span {
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.price {
  p {
    font-size: 20px;
    font-weight: 400px;
  }

  span {
    font-size: 14px;
    font-weight: 300;
  }
}

//primeiro modal

.change {
  span {
    font-weight: 500;
    font-size: 12px;
    color: green;
    cursor: pointer;
  }

  .firstModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-weight: 500;
      font-size: 25px;
    }

    p {
      font-size: 20px;
      font-weight: 275;
      line-height: 24px;
    }
  }
}

//segundo modal

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credit {
  width: 300px;
  height: 150px;
}

.titular {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;

  p {
    font-weight: 275;
    font-size: 10px;
  }
}

.codigo {
  display: flex;
  gap: 17px;
  margin-top: 5px;

  p {
    font-weight: 275;
    font-size: 10px;
  }

  .validade {
    input {
      width: 180px;
      border: none;
      border-bottom: 1px solid $brown-lighter;
    }
  }

  .cvv {
    input {
      border: none;
      border-bottom: 1px solid $brown-lighter;
      width: 135px;
    }
  }
}

.button {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  width: 100%;

  .btnBack {
    border: none;
    background-color: $white;
    color: rgba(21, 171, 114, 1);
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
      width: 25px;
      height: 25px;
    }
  }
}

.input {
  width: 99%;
  border: none;
  border-bottom: 1px solid $brown-lighter;
}

.lastModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 346px;
  gap: 20px;

  p {
    font-size: 20px;
    font-weight: 275;
  }
}
