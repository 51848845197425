.title {
  display: flex;
  .countItems {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #000;
    background-color: #ec5353;
    border-radius: 100%;
  }
}
