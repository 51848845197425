.container {

  & .datePickerWrapper {
    width: 100%;
  }
  & :global(.react-datepicker-popper) {
    z-index: 10;
  }
  & .input {
    &input,
    & input {
      height: 23px;
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: white;
    }
    // border-radius: 8px;


    & :global(.MuiFormHelperText-root) {
      margin-top: 0;
    }
    & :global(.MuiInputBase-root.Mui-disabled) {
      background: #ABABAB;
    }
  }
}
