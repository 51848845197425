
.subtext {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}

.menuButton {
  text-align: right;
}

.card {
  height: 30vh;
  margin: 3% 0 3% 0;
  overflow-y: scroll;

  .buttons {
    align-self: center;
    text-align: center;
    
    svg {
      font-size: 22px;
      cursor: pointer;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.procedimentosGrid {
  margin-bottom: 2%;
}

.btnSalvar {
  display: flex;
  justify-content: flex-end;
}

.empty_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}
