.container {
  margin: 0 auto;
  min-width: 1010px;
  width: 100%;
  // height: 100vh;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.buttonLogout {
  width: 50px;
  height: 50px;
}

.nav {
  position: sticky;
  top: 0;
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header {
  background: #fff;
  height: 71px;
}
.content {
  padding-top: 20px;
  padding-bottom: 50px;
  overflow: auto;
}

.fullHeight {
  padding-top: 0;
  height: 100vh;
  max-height: 100vh;
}
