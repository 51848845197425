@import 'variables.scss';

.user {
  float: right;
  display: flex;
  justify-content: flex-end; /* Alinhamento horizontal */
  align-items: center; /* Alinhamento vertical */
  // margin-top: 50px;
  // margin-right: 70px;
  // width: 100%;
  // background-color: #C5AA71;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 8px;
    // border: 1px solid $brown-lighter;
    width: 100%;
    height: 45px;
    gap: 5px;
    padding: 5px;
  }
}

.menu {
  width: 200px;
  position: absolute;
  top: 55px;
  right: 0;
  border: solid 1px $grey-lighter;
  border-radius: 8px;
  background-color: $white;
  z-index: 9999;

  .menuButton {
    width: 100%;
    place-content: flex-start;
  }
}
