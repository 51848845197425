@import 'variables.scss';

.container {
  .button {
    border: none;
    background-color: $white;
    color: rgba(21, 171, 114, 1);
    display: flex;
    align-items: center;
  }

  .button svg {
    transform: rotate(180deg);
    width: 25px;
    height: 25px;
  }
}
