@import 'variables.scss';

.main_container {
  display: flex;
  flex-direction: column;

  .ver_solicitacao_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 25px;

    .ver_solicitacao_top_card {
      display: flex;
      flex-direction: row;
      background-color: $grey-lighter;
      border-radius: 8px;
      padding: 30px;
      gap: 150px;
      width: -webkit-fill-available;

      .ver_solicitacao_top_card_left {
        display: flex;
        flex-direction: column;
        font-weight: 600;
      }

      .ver_solicitacao_top_card_right {
        display: flex;
        flex-direction: column;
      }
    }

    .ver_solicitacao_table_container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      table {
        border-collapse: separate;
        border-spacing: 0px 10px;
        width: 100%;
        margin-top: 0;

        thead {
          tr {
            th {
              color: black;
              border-bottom: 2px solid black;
            }
          }
        }

        tbody {
          tr {
            height: 35px;
            td {
              padding: 15px 25px;
              background-color: $grey-lighter;
              img {
                width: 15px;
                height: 15px;
              }

              &:first-child {
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
              }
            }
          }
        }
      }
    }

    .ver_solicitacao_orcamentos_container {
      display: flex;
      flex-direction: column;
      width: 100%;

      h1 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 15px;
      }

      .ver_solicitacao_orcamentos_inner_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:nth-child(even) {
          background-color: $grey-lighter;
          border-radius: 8px;
        }

        .ver_solicitacao_orcamentos_inner_container_left {
          display: flex;
          flex-direction: column;
          padding: 10px 25px !important;
          font-weight: 600;
        }

        .ver_solicitacao_orcamentos_inner_container_middle {
          display: flex;
          flex-direction: column;
          padding: 10px 0px !important;
        }

        .ver_solicitacao_orcamentos_inner_container_right {
          display: flex;
          margin-left: auto;
          padding: 10px !important;

          button {
            background-color: $green;
            height: 30px;
            width: 120px;
            gap: 10px;
            margin: 0;
            font-size: 12px;
            text-transform: none;
            text-wrap: nowrap;
            font-weight: 300;
          }
        }
      }

      .ver_solicitacao_orcamentos_status_span {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .ver_solicitacao_buttons_container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ver_solicitacao_button_voltar_container {
        display: flex;
        justify-content: center;
      }
    }
  }
}
