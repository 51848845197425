@import 'variables.scss';

.ver_solicitacao_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 25px;

  .ver_solicitacao_top_card {
    display: flex;
    flex-direction: row;
    background-color: $grey-lighter;
    border-radius: 8px;
    padding: 30px;
    gap: 150px;
    width: -webkit-fill-available;

    .ver_solicitacao_top_card_left {
      display: flex;
      flex-direction: column;
      font-weight: 600;

      button {
        margin-top: 20px;
      }
    }

    .ver_solicitacao_top_card_right {
      display: flex;
      flex-direction: column;
    }
  }

  .ver_solicitacao_table_container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    table {
      border-collapse: separate;
      border-spacing: 0px 10px;
      width: 100%;
      margin-top: 0;

      thead {
        tr {
          th {
            color: black;
            border-bottom: 2px solid black;
          }
        }
      }

      tbody {
        tr {
          height: 35px;
          td {
            padding: 15px 25px;
            background-color: $grey-lighter;
            img {
              width: 15px;
              height: 15px;
            }

            &:first-child {
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }

            &:last-child {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }
      }
    }
  }

  .ver_solicitacao_orcamentos_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 15px;
      border-bottom: 2px solid $black;
    }

    .ver_solicitacao_orcamentos_inner_container {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:nth-child(even) {
        background-color: $grey-lighter;
        border-radius: 8px;
      }

      .ver_solicitacao_orcamentos_inner_container_left {
        display: flex;
        flex-direction: column;
        padding: 10px 25px !important;
        font-weight: 600;
      }

      .ver_solicitacao_orcamentos_inner_container_middle {
        display: flex;
        flex-direction: column;
        padding: 10px 0px !important;
      }

      .ver_solicitacao_orcamentos_inner_container_right {
        display: flex;
        margin-left: auto;
        padding: 10px !important;

        button {
          background-color: $green;
          height: 30px;
          width: 120px;
          gap: 10px;
          margin: 0;
          font-size: 12px;
          text-transform: none;
          text-wrap: nowrap;
          font-weight: 300;
        }
      }
    }

    .ver_solicitacao_orcamentos_status_span {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .ver_solicitacao_buttons_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .ver_solicitacao_buttons_inner_container {
      display: flex;
      justify-content: flex-end;
      gap: 15px;

      .ver_solicitacao_button_fechar {
        margin-right: auto;
      }

      .ver_solicitacao_button_reprovar {
        background-color: $red;
      }

      .ver_solicitacao_button_aprovar {
        background-color: $green;
      }
    }
  }
}

// :has(.ver_solicitacao_modal) {
//   background-color: transparent;
// }

.ver_solicitacao_modal_reprovar_body {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 300px;

  button {
    width: 100px;
    margin-left: auto;
  }
}

.ver_solicitacao_modal_aprovar_body {
  width: calc(65vw - 100px);
  display: flex;
  flex-direction: column;

  h3 {
    align-self: center;
  }

  button {
    width: 100px;
    align-self: center;
  }

  .input_qtd {
    padding: 0.76rem;
    border: 1px solid $grey;
    outline-color: $brown-lighter;
  }

  .inputs {
    margin: 10px 0 30px 0;
  }

  table {
    border-spacing: 0;
    margin-bottom: 60px;
  }
  
  .tab {
    display: flex;
    flex-direction: row;
    gap: 20px;
    border-bottom: 1px solid $brown-lighter;
    color: $brown-lighter;
    text-decoration: none;
    margin-bottom: 20px;

    .element {
      cursor: pointer;
      color: $brown-lighter;
    }

    .elementActive {
      color: $brown-lighter;
      border-bottom: 5px solid $brown-lighter;
      cursor: pointer;
    }
  }

  .error {
    color: $red;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }
}
