@import 'variables.scss';

.container {
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    margin-bottom: 15px;

    .title {
      font-size: 20px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
    width: 600px;

    .date_container {
      display: flex;
      flex-direction: column;

      span {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;

    button {
      font-size: 12px;
      width: 100px;
      height: 50px;
    }

    .button_arrived {
      background-color: $green;
      margin-left: auto;
    }  
  }
  
  .footer_error {
    font-size: 13px;
    margin-top: 20px;
    color: $red;
  }
}
