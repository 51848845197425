@import 'variables.scss';

.tab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid $brown-lighter;
  margin: 10px 0px 30px 0px;
  color: $brown-lighter;
  text-decoration: none;

  .element {
    color: $brown-lighter;
  }

  .elementActive {
    color: $brown-lighter;
    border-bottom: 5px solid $brown-lighter;
    cursor: pointer;
  }
}
